<template>
  <div  class="body">
    <div class="authentication">
      {{SystemUrl == 'gx'? '实名认证':'信息绑定'}}
    </div>
     <div style="margin-top:100px">
       <van-form @submit="onSubmit">
         <van-field
             v-model="form.type"
             label="证照类型:"
             placeholder="证照类型"
             disabled
         />
         <van-field
             v-model="form.nameText"
             label="姓名:"
             placeholder="姓名"
             required
             :rules="[{ required: true, message: '请填写姓名' }]"
         />
         <van-field
             v-model="form.numberText"
             label="身份证号码:"
             placeholder="身份证号码"
             required
             :rules="[{ pattern, message: '请输入正确身份证号码' }]"
         />
         <div style="height:50px;width:100%"></div>
         <van-button round type="primary" size="large">{{SystemUrl == 'gx'?'进行人脸核验':'保存'}}</van-button>
       </van-form>
     </div>
  </div>
</template>

<script>
import {SystemUrl} from "@/settings";
import request from "@/api";

export default {
  name: '',
  components: {

  },
  props: {},
  data() {
    return {
      pattern:/^[1-9]\d{5}(19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9X]/,
      form:{
        type:'身份证',
        nameText:'',
        numberText:''
      },
      SystemUrl: SystemUrl,
    }
  },
  computed: {

  },
  watch: {
  },
  created() {
    let token=this.$route.query.token
    console.log(token)
    if(token){
      localStorage.setItem("authenticationToken",token)
    }
  },
  mounted() {

  },
  methods: {
    onSubmit(values){
      if (this.form.nameText.length<2){
        this.$dialog({ message: '请填写名字且不能少于两个字！' });
        return
      }
      if (this.SystemUrl == 'gx'){
        this.$router.push({path: '/faceRecognition', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
      }else {
        request.post(process.env.VUE_APP_PX_URL+'/uua/verifyFace',{
          nameText:this.form.nameText,
          numberText:this.form.numberText
        }).then(res=>{
          if (res.data=='success'){
            this.$router.push({path: '/authSuccess', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
          }else {
            this.$router.push({path: '/authErr', query: {"numberText":this.form.numberText,"nameText":this.form.nameText}})
          }
        }).catch(err=>{
          this.$toast.fail(err.message)
        })
      }
    }
  },
}
</script>
<style lang='scss' scoped>
.body{
  background-color: white;
}
.connect{
  text-align: center;
  margin-top:30px;
  color: blue;
}
.authentication{
  text-align: center;
  padding-top:100px;
  font-size: 30px;
  font-weight: bold;
}
</style>
